import Vue from 'vue'
import Vuex from 'vuex'
import gaugeList from './modules/gaugeList'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // http://xbssc.zhihanyixing.com
    requestApi: 'http://user.yuanaiconsulting.com/api',
    downLoadUrl: 'http://user.yuanaiconsulting.com'
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    gaugeList
  }
})
