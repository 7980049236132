import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './element-variables.scss'
import print from './assets/vue-print-nb'
import JsonExcel from 'vue-json-excel'
import moment  from 'moment'
import './utils/rem';
import highchartsVue from 'highcharts-vue'

import axios from 'axios'
//需要挂载到Vue原型上
Vue.component("downloadExcel", JsonExcel);
Vue.config.productionTip = false
Vue.prototype.$http = axios;
Vue.use(print);
Vue.use(highchartsVue);
Vue.prototype.$print = print;
Vue.prototype.$moment = moment;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset-UTF-8';
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
