import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login/Login.vue'
// import ViewReport from'@/views/psychologicalEvaluation/result/ViewReport.vue'
import {
  Message
} from 'element-ui';
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    redirect:'/home/userdata',
    component: () => import( /* webpackChunkName: "about" */ '../views/home/Home.vue'),
    // 前置导航守卫
    beforeEnter: async (to, from, next) => {
      // 获取本地的 token
      const token = sessionStorage.token;
      if (token) {
        // 本地有 token
        // const data = await api.users.getLogin({
        //   account: sessionStorage.account,
        //   password: sessionStorage.password,
        // });
        // if (data.code) {
        //   // 如果后端返回了用户信息，可以将用户信息保存在 Vuex 或本地存储中
        //   store.commit("SET_USER_INFO", data.data);
        //   // token 验证有效，允许用户进入权限页面
        next();
        // }
      } else {
        // 本地没有 token
        // ElementUI.Message({
        //   showClose: true,
        //   message: '这是一条消息提示'
        // });
        // Notification.error({
        //   title: '你还未登录，请先登录!',
        //   duration: 5000
        // });
        Message({
          showClose: true,
          message: '你还未登录，请先登录！'
        });
        next("/");
        }
       },
       children:[
        {
          path: '/home/upfile',
          name: 'upfile',
          component: () => import('../views/upfile/Upfile.vue'),
       },
       {
        path: '/home/upfilelog',
        name: 'upfileLog',
        component: () => import('../views/upfile/UpfileLog.vue'),
       },
       {
        path: '/home/userdata',
        name: 'usersData',
        component: () => import('../views/users/UserData.vue'),
       },
       {
        path: '/home/userlist',
        name: 'usersLs',
        component: () => import('../views/users/UserList.vue'),
       },
       {
        path: '/home/statistics',
        name: 'statistics',
        component: () => import('../views/statistics/Statistics.vue'),
      },
      {
        path: '/home/details/:admissionNo',
        name: 'details',
        hidden:true,
        component: () => import('../views/see/Detail.vue'),
      },
       ]
  },
]


const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router