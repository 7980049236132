//等比适配配置
const baseSize = 16;

function setRem(){
      const scale = document.documentElement.clientWidth /1280;
      document.documentElement.style.fontSize = `${baseSize * Math.min(scale,3)}px`;
}

setRem();
window.onresize = ()=>{
    setRem();
}